import Script from 'next/script'
import '@styles/globals.css'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { usePageViewAnalytics } from '@src/lib/analytics'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { authApi, useUser } from '@src/api/auth'
import { useEffect, useState } from 'react'
import { handleMessage, identify, page } from '@src/lib/whoami'

function MyApp({ Component, pageProps }: AppProps) {
  const [trackerReady, setTrackerReady] = useState(false)
  const [iframeReady, setIframeReady] = useState(false)
  const router = useRouter()
  const isProd = process.env.NODE_ENV === 'production'
  const user = useUser()

  usePageViewAnalytics()
  useEffect(() => {
    authApi.refreshTokenIfNeeded()
  }, [])

  // whoamiSDK setup
  useEffect(() => {
    setIframeReady(true)
    window.addEventListener('message', (e) => handleMessage(e, () => setTrackerReady(true)))
  }, [])

  useEffect(() => {
    if (user) {
      identify({
        barstoolUserID: String(user?.id)
      })
    }
  }, [user, user?.id])

  useEffect(() => {
    if (trackerReady) {
      page()
    }
  }, [trackerReady, router.asPath])

  return (
    <Elements stripe={loadStripe(process.env.NEXT_PUBLIC_STRIPE_API_KEY ?? '')}>
      <Component {...pageProps} />
      <Script
        src={`https://whoami-static.barstoolsports.com/${
          isProd ? 'prod' : 'dev'
        }/0.1.9/iife.min.js`}
        strategy='afterInteractive'
      />
      <Script
        id='cast'
        strategy='lazyOnload'
        src='https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1'
      />
      {iframeReady && <iframe src='https://barstoolsports.com/bridge.html' className='hidden' />}
    </Elements>
  )
}

export default MyApp
