import { Service } from '@api/common'
import { apiHost } from '@src/lib/env'
import type { Event } from './types'

export class EventAuthApi extends Service<Event> {
  constructor() {
    super('/auth', apiHost)
  }

  async refresh() {
    const result = await this.post(`/auth/refresh`)
    return result
  }
}

export const eventAuthApi = new EventAuthApi()
