import { FacebookPixel } from '@barstoolsports/web-sdk'

class BarstoolFacebookPixel extends FacebookPixel {
  constructor() {
    super([process.env.NEXT_PUBLIC_FB_WEB_ID ?? ''])
  }

  async pageView() {
    this.sendEvent('track', 'PageView', {}, {})
  }
}

const Facebook = new BarstoolFacebookPixel()

export const loadPixels = () => {
  Facebook.init()
}

export const pageView = () => {
  Facebook.pageView()
}
