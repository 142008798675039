import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { pageView, loadPixels } from '@lib/page-view'
import ga4 from 'react-ga4'
import { Event, Purchase } from '@src/api/types'
import { identify } from '@lib/whoami'
import { useCurrentUser } from '@src/api/user'

const gaMeasurementId = process.env.NEXT_PUBLIC_GA_ID

export const useWhoamiIdentification = () => {
  const { data: user } = useCurrentUser()
  useEffect(() => {
    if (user && user.id)
      identify({
        barstoolUserID: user.id,
        stripeCustomerID: user?.stripe_customer
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, user?.id])
}

export function usePageViewAnalytics() {
  const router = useRouter()

  useEffect(() => {
    if (gaMeasurementId) {
      ga4.initialize(gaMeasurementId)
      ga4.set({ transport: 'beacon' })
    }
    loadPixels()
    pageView()
  }, [])

  useEffect(() => {
    const handleRouteChange = () => {
      if (gaMeasurementId) {
        ga4.send({ hitType: 'pageview', page: router.asPath })
      }
      pageView()
    }
    // When the component is mounted, subscribe to router changes
    // and log those page views
    router.events.on('routeChangeComplete', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export function sendPurchase(purchase: Purchase, event: Event) {
  gtag('event', 'purchase', {
    transaction_id: purchase.id,
    value: purchase.spent,
    currency: 'USD',
    affiliation: 'roughnrowdy',
    tax: purchase.tax,
    items: [
      {
        index: 0,
        item_id: event.id,
        item_name: event.name,
        affiliation: 'roughnrowdy',
        quantity: 1,
        price: event.price
      }
    ]
  })
}
