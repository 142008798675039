import { type Content } from '../api/types/whoami'

interface whoAmISDKEventData {
  ajs_anonymous_id?: string
  ajs_user_id?: string
}

export const handleMessage = (event: MessageEvent, callback: () => void) => {
  const url = 'https://www.barstoolsports.com'
  if (event.origin.startsWith(url)) {
    try {
      const data: whoAmISDKEventData = JSON.parse(event.data)
      const hasAnonID = data?.ajs_anonymous_id
      const hasBarstoolUserID = data?.ajs_user_id

      if (window != undefined && window.whoamiSDK) {
        const whoamiSDK = window.whoamiSDK
        if (hasAnonID) {
          whoamiSDK.setAnonymousID(hasAnonID)
        }
        if (hasBarstoolUserID) {
          whoamiSDK.identify({ barstoolUserID: hasBarstoolUserID })
        }
        if (hasAnonID) callback?.()
        window.removeEventListener('message', (event: MessageEvent) =>
          handleMessage(event, callback)
        )
      }
    } catch (error) {
      console.error('Error parsing message data:', error)
    }
  }
}

export function track(event: string, content: Content): void {
  // escape early for the server side component render because the window doesnt exist
  if (!window.whoamiSDK) return
  return window.whoamiSDK?.track(event, content)
}
export function identify(content: Content['user']): void {
  if (!window.whoamiSDK) return
  return window.whoamiSDK?.identify(content)
}
export function page(): void {
  if (!window.whoamiSDK) return
  return window.whoamiSDK?.page()
}

export function getAnonymousID() {
  if (!window.whoamiSDK) return
  const anonymousID = window?.whoamiSDK?.anonymousID
  if (anonymousID) {
    return anonymousID
  }
}
