import { unionApiHost } from '@src/lib/env'
import storage from '@src/lib/storage'
import { Service, SWRConfiguration, useGet } from './common'
import { ReadParams, User, UserCredentials } from './types'

class UserApi extends Service<UserCredentials> {
  constructor() {
    super('user', unionApiHost)
  }

  async update(id: string, data: User) {
    const result: User = await this.put(`/user/${id}`, data)
    return result
  }

  async addPaymentMethod(token: string) {
    if (!storage.user) {
      throw new Error('Must be logged in to add a payment method')
    }
    const result = await this.put(`/user/${storage?.user?.id}/stripe/customer`, { source: token })
    return result
  }
}

export const userApi = new UserApi()

export function useCurrentUser(params?: ReadParams, config?: SWRConfiguration) {
  const userId = storage?.user?.id

  const { data, error, mutate } = useGet<any>(userApi, `/user/${userId}`, params, config)

  async function update(data: User) {
    const result = await userApi.update(userId, data)
    await mutate()
    return result
  }

  return {
    data,
    error,
    isLoading: !(error || data),
    mutate,
    update
  }
}

export function useStripeCustomer(params?: ReadParams, config?: SWRConfiguration) {
  const id = storage?.user?.id

  const { data, error, mutate } = useGet<any>(
    userApi,
    id ? `/user/${id}/stripe/customer` : null,
    params,
    config
  )

  return {
    data,
    error,
    isLoading: !(error || data),
    mutate
  }
}
