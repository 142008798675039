import { unionApiHost } from '@src/lib/env'
import storage from '@src/lib/storage'
import { isServer } from '@src/lib/utils'
import jwtDecode from 'jwt-decode'
import { Service } from './common'
import { eventAuthApi } from './event-auth'
import { AuthResponse, User, UserCredentials } from './types'
import { ApiError } from './utils'

class AuthApi extends Service<UserCredentials> {
  constructor() {
    super('auth', unionApiHost)
  }

  async emailCheck(email: string) {
    return await this.post(`/auth/email-check`, { email, source: 'web' })
  }

  async login(data: { email: string; password: string }) {
    const result: AuthResponse = await this.post(`/auth/login`, data)
    storage.authToken = result.token
    storage.user = result.user
    await this.refreshToken()
    return result
  }

  async signup(data: { email: string; password: string }) {
    const result: AuthResponse = await this.post('/auth/signup', data)
    storage.authToken = result.token
    storage.user = result.user
    await this.refreshToken()
    return result
  }

  async update(id: string, data: User) {
    const result: User = await this.put(`/auth/${id}`, data)
    return result
  }

  async forgotPassword(email: string) {
    return await this.post('/auth/forgot-password', { email })
  }

  async refreshToken() {
    if (storage.authToken) {
      try {
        await eventAuthApi.refresh()
      } catch (e) {
        if (e instanceof ApiError && e.status === 401) {
          this.logout()
          throw e
        }
      }
    }
  }

  async refreshTokenIfNeeded() {
    if (storage.authToken) {
      const authToken: { iat: number; exp: number; data: any } = jwtDecode(storage.authToken)
      if (!authToken) return
      const issued = new Date(authToken.iat * 1000)
      const expires = new Date(authToken.exp * 1000)
      const currentDate = new Date()
      const shouldRefreshToken =
        issued > currentDate || expires < currentDate || !('ppv_events' in authToken.data)
      if (shouldRefreshToken) {
        await this.refreshToken()
      }
    }
  }

  hasEventAccess(eventId: string) {
    if (!storage.authToken || !storage.user) return false
    const authToken: { iat: number; exp: number; data: any } = jwtDecode(storage.authToken)
    const events = authToken.data?.ppv_events ?? []
    return events.includes(eventId)
  }

  logout() {
    storage.authToken = null
    storage.user = null
  }
}

export const authApi = new AuthApi()

export function useUserAuth(): boolean {
  return isServer ? false : storage.authToken && storage?.user
}

export function useUser(): User | undefined {
  if (isServer) return undefined
  return storage?.user
}
