import { flattenObject } from '@lib/utils'
import storage from '@src/lib/storage'
import useSWR, { SWRResponse, SWRConfiguration } from 'swr'
// import { apiHost as eventsApiHost } from '@lib/env'
import type {
  Base,
  AnyKeys,
  HttpMethod,
  HttpHeaders,
  HttpBody,
  HttpParams,
  ListParams,
  ReadParams
} from './types'
import { ApiError } from './utils'

export class Service<Model extends Base> {
  basePath: string
  apiHost: string

  constructor(basePath: string, apiHost: string) {
    this.basePath = basePath
    this.apiHost = apiHost
  }

  async read(id: string, params?: ReadParams): Promise<Model> {
    return this.get(`${this.basePath}/${id}`, params ? flattenObject(params) : undefined)
  }

  async create(body: AnyKeys<Model>): Promise<Model> {
    return this.post(this.basePath, body)
  }

  async list(params?: ListParams<Model>): Promise<Model[]> {
    return this.get(this.basePath, params ? flattenObject(params) : undefined)
  }

  async readAndUpdate(id: string, body: AnyKeys<Model>): Promise<Model> {
    return this.put(`${this.basePath}/${id}`, body)
  }

  async readAndDelete(id: string, params?: HttpParams): Promise<Model> {
    return this.delete(`${this.basePath}/${id}`, params)
  }

  async get<T>(path: string, params?: HttpParams): Promise<T> {
    return this.request({ path, method: 'get', params })
  }

  async delete<T>(path: string, params?: HttpParams): Promise<T> {
    return this.request({ path, method: 'delete', params })
  }

  async post<T>(path: string, body?: HttpBody): Promise<T> {
    return this.request({ path, method: 'post', body })
  }

  async put<T>(path: string, body?: HttpBody): Promise<T> {
    return this.request({ path, method: 'put', body })
  }

  async patch<T>(path: string, body: HttpBody): Promise<T> {
    return this.request({ path, method: 'patch', body })
  }

  async request<T>(props: {
    path: string
    method: HttpMethod
    params?: HttpParams
    body?: HttpBody
  }): Promise<T> {
    // Build request
    const req = this.buildRequest(props)

    const { authToken } = storage

    if (req.headers && authToken) {
      req.headers.Authorization = `Bearer ${authToken}`
    }

    // Issue request
    const res = await fetch(`${this.apiHost}${req.path}`, {
      method: req.method,
      body: req.body,
      headers: {
        'Content-Type': 'application/json',
        ...req.headers
      }
    })

    const xUnionSetAuthToken = res.headers.get('x-union-set-authorization')
    if (xUnionSetAuthToken) {
      storage.authToken = xUnionSetAuthToken
    }

    // Parse json response
    const json = await res.json()

    // Throw response as an error if we did not receive a 200
    if (!res.ok) {
      throw new ApiError(res.status, json.message, json.error)
    }

    return json
  }

  private buildRequest(props: {
    path: string
    method: HttpMethod
    params?: HttpParams
    body?: HttpBody
  }): { path: string; method: string; headers: HttpHeaders; body?: string } {
    let params = undefined
    let body = undefined

    switch (props.method) {
      case 'get':
      case 'delete':
      case 'options': {
        params = props.params
          ? Object.keys(props.params)
              .sort()
              .filter(
                (key: string) => props.params?.[key] !== undefined && props.params?.[key] !== null
              )
              .map((key: string) => [
                encodeURIComponent(key),
                encodeURIComponent(props.params?.[key] as string | number | boolean)
              ])
              .map(([key, value]) => `${key}=${value}`)
              .join('&')
          : ''
        break
      }
      case 'post':
      case 'put':
      case 'patch': {
        body = JSON.stringify(props.body ?? {})
        break
      }
      default:
        throw new Error('Invalid request method')
    }

    return {
      path: [props.path, params].filter(Boolean).join('?'),
      method: props.method.toUpperCase(),
      body: body,
      headers: {}
    }
  }
}

export type { SWRConfiguration }

export function useRead<T extends Base>(
  service: Service<T>,
  id: string | undefined,
  params?: ReadParams,
  config?: SWRConfiguration
): SWRResponse<T, Error> {
  const cacheKey = JSON.stringify(params ?? {})
  const fetcher = () => {
    if (!id) {
      return null
    }
    return service.read(id, params)
  }
  return useSWR(id === undefined ? null : [`${service.basePath}/${id}`, cacheKey], fetcher, config)
}

export function useList<T extends Base>(
  service: Service<T>,
  params?: ListParams<T>,
  config?: SWRConfiguration
): SWRResponse<T[], Error> {
  const cacheKey = JSON.stringify(params ?? {})
  const fetcher = () => service.list(params)
  return useSWR([`${service.basePath}`, cacheKey], fetcher, config)
}

export function useGet<T>(
  service: Service<any>,
  path: string | null,
  params?: HttpParams,
  config?: SWRConfiguration
): SWRResponse<T, Error> {
  const cacheKey = JSON.stringify(params ?? {})
  const fetcher = () => {
    if (!path) {
      return null
    }
    return service.get<T>(path, params)
  }
  return useSWR(path ? [path, cacheKey] : null, fetcher, config)
}

export function useGetSafe<T>(
  service: Service<any>,
  path: string | null,
  params?: HttpParams,
  shouldFetch?: () => boolean | {},
  config?: SWRConfiguration
): SWRResponse<T, Error> {
  const cacheKey = JSON.stringify(params ?? {})
  const fetcher = () => {
    if (typeof shouldFetch === 'function' && shouldFetch() === false) {
      return null
    }
    if (!path) {
      return null
    }
    return service.get<T>(path, params)
  }
  return useSWR(path ? [path, cacheKey] : null, fetcher, config)
}
